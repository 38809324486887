exports.install = function(Vue, options) {

  // 时间格式转换 （年月日）
  Vue.prototype.getTime = function(date) {
    var e = new Date(date),
      month = '',
      day = '',
      hour = '',
      min = '',
      sec = '';
    if (e.getMonth() + 1 < 10) {
      month = '0' + (e.getMonth() + 1)
    } else {
      month = e.getMonth() + 1
    }
    if (e.getDate() < 10) {
      day = '0' + (e.getDate())
    } else {
      day = e.getDate()
    }
    return e.getFullYear() + '-' + month + '-' + day
  }

  // 时间格式转换 （年月日时分秒）
  Vue.prototype.getTime2 = function(date) {
    var e = new Date(date),
      month = '',
      day = '',
      hour = '',
      min = '',
      sec = '';
    if (e.getMonth() + 1 < 10) {
      month = '0' + (e.getMonth() + 1)
    } else {
      month = e.getMonth() + 1
    }
    if (e.getDate() < 10) {
      day = '0' + (e.getDate())
    } else {
      day = e.getDate()
    }
    if (e.getHours() < 10) {
      hour = '0' + (e.getHours())
    } else {
      hour = e.getHours()
    }
    if (e.getMinutes() < 10) {
      min = '0' + (e.getMinutes())
    } else {
      min = e.getMinutes()
    }
    if (e.getSeconds() < 10) {
      sec = '0' + (e.getSeconds())
    } else {
      sec = e.getSeconds()
    }
    return e.getFullYear() + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + sec
  }

  // 时间转化为毫秒
  Vue.prototype.getTime3 = function(date) {
    var e = date.replace(new RegExp("-", "gm"), "/")
    return (new Date(e)).getTime()
  }

  // 得到几天前的时间戳
  Vue.prototype.getTime4 = function(date) {
    var e = new Date().getTime() - date * 86400000
    return new Date(e)
  }

  // 时间戳转化为毫秒
  Vue.prototype.getTime5 = function(date) {
    var e = new Date(date).getTime()
    return e
  }

  // 得到时分秒
  Vue.prototype.getTime6 = function(date) {
    var e = new Date(date),
      hour = '',
      min = '',
      sec = '';
    if (e.getHours() < 10) {
      hour = '0' + (e.getHours())
    } else {
      hour = e.getHours()
    }
    if (e.getMinutes() < 10) {
      min = '0' + (e.getMinutes())
    } else {
      min = e.getMinutes()
    }
    if (e.getSeconds() < 10) {
      sec = '0' + (e.getSeconds())
    } else {
      sec = e.getSeconds()
    }
    return hour + ':' + min + ':' + sec
  }

  // 数组的去重
  Vue.prototype.unique = function(arr) {
    var ra = new Array()
    for (var i = 0; i < arr.length; i++) {
      if (!ra.contains(arr[i])) {
        ra.push(arr[i])
      }
    }
    return ra
  }

  // 随机返回数组的某一个元素
  Vue.prototype.randomItem = function(ary) {
    return ary[Math.ceil(Math.random() * ary.length)]
  }

  // 数组的随机排列
  Vue.prototype.shuffle = function(ary) {
    var input = this
    for (var i = input.length - 1; i >= 0; i--) {
      var randomIndex = Math.floor(Math.random() * (i + 1))
      var itemAtIndex = input[randomIndex]
      input[randomIndex] = input[i]
      input[i] = itemAtIndex
    }
    return input
  }

  // 判断是不是数组
  Vue.prototype.isArray = function(ary) {
    var objectToStringFn = Object.prototype.toString
    var arrayToStringResult = objectToStringFn.call([])
    return function(subject) {
      return objectToStringFn.call(subject) === arrayToStringResult
    }
  }

  // 计算两个日期之间的间隔天数
  Vue.prototype.dayMinus = function(startDate, endDate) {
    var days = Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24))
    return days
  }

  // 图片流转base64
  Vue.prototype.transformArrayBufferToBase64 = function(buffer) {
    var binary = ''
    var bytes = new Uint8Array(buffer)
    for (var len = bytes.byteLength, i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i])
    }
    return window.btoa(binary)
  }

  // base64转Blob
  Vue.prototype.dataURLtoBlob = function(dataurl) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new Blob([u8arr], {
      type: mime
    })
  }

  // Blob转base64
  Vue.prototype.blobToDataURL = function(blob, callback) {
    let a = new FileReader()
    a.onload = function(e) {
      callback(e.target.result)
    }
    a.readAsDataURL(blob)
  }

  // ArrayBuffer 转 base64
  Vue.prototype.ArrayBufferToBase64 = function(buffer) {
    //第一步，将ArrayBuffer转为二进制字符串
    var binary = '';
    var bytes = new Uint8Array(buffer);
    for (var len = bytes.byteLength, i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    //将二进制字符串转为base64字符串
    return window.btoa(binary);
  }

  //获取两日期之间日期列表函数
  Vue.prototype.getdiffdate = function(stime,etime){
    //初始化日期列表，数组
    var diffdate = new Array();
    var i=0;
    //开始日期小于等于结束日期,并循环
    while(stime<=etime){
      diffdate[i] = stime;

      //获取开始日期时间戳
      var stime_ts = new Date(stime).getTime();
      console.log('当前日期：'+stime   +'当前时间戳：'+stime_ts);

      //增加一天时间戳后的日期
      var next_date = stime_ts + (24*60*60*1000);

      //拼接年月日，这里的月份会返回（0-11），所以要+1
      var next_dates_y = new Date(next_date).getFullYear()+'-';
      var next_dates_m = (new Date(next_date).getMonth()+1 < 10)?'0'+(new Date(next_date).getMonth()+1)+'-':(new Date(next_date).getMonth()+1)+'-';
      var next_dates_d = (new Date(next_date).getDate() < 10)?'0'+new Date(next_date).getDate():new Date(next_date).getDate();

      stime = next_dates_y+next_dates_m+next_dates_d;

      //增加数组key
      i++;
    }
    return diffdate
  }

  // 生成随机字母
  Vue.prototype.randomStr = function(len, arr) {
    var ans = '';
    for(var i = len; i > 0; i--) {
      ans +=
      arr[Math.floor(Math.random() * arr.length)];
    }
    return ans;
  }

}
