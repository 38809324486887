import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    signature: '',
    perSon:[],
  },
  mutations: {
    login(state,name){
      state.signature = name
    },
    ediot(state, data) {
      state.perSon[data.index] = { ...state.perSon[data.index], ...data.data };
      state.perSon = [...state.perSon]
    },
    ADDPER(state, data) {
      let cardNum = state.perSon.every(item => item.cardNum !== data.cardNum);
      cardNum ? state.perSon.unshift(data) : '';
    },
    DELETEPER(state, data) {
      state.perSon[data].isSelected = false;
    },
    CLEAR(state){
      state.perSon=[]
    },
    CHECKED(state,data) {
      state.perSon[data.index].isSelected = data.checked
    }
  },
  getters: {
    selected(state) {
      return state.perSon.filter(item => item.isSelected)
    },
    getPer(state) {
      return (index) => state.perSon[index]
    },
    getMaster(state) {
      console.log(state.perSon.find(item => item.master));
      return state.perSon.find(item => item.master).phone;
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState({
    reducer(val) {
      return {
        // 只储存state中的token
        signature: val.signature,
        perSon: val.perSon,
      }
    }
  })]
})
