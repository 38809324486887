import CryptoJS from "crypto-js";
    
const key = CryptoJS.enc.Utf8.parse("S8IZhz3zeo8engtZ");  //十六位十六进制数作为密钥
const iv = CryptoJS.enc.Utf8.parse('CDXjiHeBOU8MrhfF');   //十六位十六进制数作为密钥偏移量

//解密
export function Decrypt(word) {
    let encrypted = CryptoJS.AES.decrypt(word, key, { iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 })
    return CryptoJS.enc.Utf8.stringify(encrypted).toString()
}

// 身份证号脱敏

export function Desensitization(str,beginLen,endLen) {
  
    
    }


 export   function noPassByName(str){
	    if(null != str && str != undefined){
	        if(str.length <= 3){
	            return "*" + str.substring(1,str.length);
	        } else if(str.length > 3 && str.length <= 6){
	            return "**" + str.substring(2,str.length);
	        } else if(str.length > 6){
	            return str.substring(0,2) + "****" + str.substring(6,str.length)
	        }
	    } else {
	        return "";
	    }
	}
