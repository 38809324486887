import axios from "axios";
import router from "../router"
import Vue from 'vue'
let baseUrl = 'https://museum-voice.bowu66.com'//线上
// let baseUrl = "http://lanproxy.zhoukc.top:40001" //本地
Vue.prototype.baseUrl =baseUrl
Vue.prototype.museumId =9215

// let baseUrl = 'https://test.bowu66.com'//测试

export let network = function (url, data = {}, method = 'get') {
    return axios({
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            appId:sessionStorage.getItem('appid'),
            token:sessionStorage.getItem('openid')
            // token:localStorage.getItem('userId')
          },
        url: baseUrl + url,
        method,
        params: method === 'get' ? data : {},
        data: JSON.stringify(data)
      
    })
    
};

