import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "../utils/rem"
import "../utils/openjsbridge.icity"
import { Icon, Tab, Tabs, Search, Popup, Cell, Field, DatetimePicker, Form, Picker, Checkbox, CheckboxGroup, Dialog, Toast, Calendar } from 'vant';
import { register, getToken } from '../src/api/api'
Vue.config.productionTip = false
Vue.use(Search)
Vue.use(Popup)
Vue.use(Cell)
Vue.use(Field);
Vue.use(DatetimePicker);
Vue.use(Form)
Vue.use(Picker)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Calendar)
Vue.use(Dialog);
Vue.use(Toast)
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Icon);
import base from './assets/base.js'
Vue.use(base)
// const appId = 'wx0e3c3ad0271fa13c';
// function getUrlParam(name) {   //name为要获取的参数名
// var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
// var rrr = decodeURIComponent(window.location.search);
// console.log(window.location.href)


// iCityBridge.init(function (hasLogin) {
//   //hasLogin true:已登录，false:未登录
//   console.log(hasLogin)
//   if(!hasLogin){
//     console.log(1)
//     iCityBridge.login()
//   }
// });


router.beforeEach((to, from, next) => {

  iCityBridge.init(function (hasLogin) {
    //hasLogin true:已登录，false:未登录
    console.log(hasLogin)

    var flag = iCityBridge.hasLogin(); //获取登录状态
    // flag true:已登录，false:未登录
    if (!flag) {
      console.log(flag)
      iCityBridge.login();  //调起登录
    } else {
      var rrr = decodeURIComponent(window.location.href)
      // var rrr = decodeURIComponent('https://app.zwfw.nmg.gov.cn:4443/icity/auth2.0/authorize?app_id=dee9f295-146d-4312-82d5-c4d4553bfc9c&redirect_uri=https%3A%2F%2Fxamkjg.bowu66.cn&scope=base_info,auth_user')
      // var rrr = decodeURIComponent('https://xamkjg.bowu66.cn/?app_id=dee9f295-146d-4312-82d5-c4d4553bfc9c&code=201d8d1b-40dd-4db1-a42b-fa4404967353&open_id=BB4E4B441EFBDFEDF11A485627586BAA&state=')
      if(rrr.indexOf("app_id")!=-1){
       
        var flag2 = iCityBridge.hasAuth();
        if (!flag2) {
          iCityBridge.auth();
        } else {
         
          var appid = rrr.split('app_id=')[1].split('&')[0]
          
          var code = rrr.split('code=')[1].split('&')[0]
          var openid = rrr.split('open_id=')[1].split('&')[0]
          sessionStorage.setItem('appid', appid)
          sessionStorage.setItem('code', code)
          sessionStorage.setItem('openid', openid)
          register({ openid: sessionStorage.getItem('openid') }).then((res) => {
            if (res.data.data == true) {
              getToken({ code: sessionStorage.getItem('code') }).then((res) => {
                console.log(res)
              })
              console.log(res.data.data)
  
              next()
            }
          });
        }
      }else{
        console.log(2)
       window.location.href='https://app.zwfw.nmg.gov.cn:4443/icity/auth2.0/authorize?app_id=dee9f295-146d-4312-82d5-c4d4553bfc9c&redirect_uri=https%3A%2F%2Fxamkjg.bowu66.cn&scope=base_info,auth_user'
      }
    
    }

  });


})



// register({
//   openid:sessionStorage.getItem('openid')
// }).then((res)=>{
//   console.log(res)
// })
// })
// var r = rrr.substr(1).match(reg);
// if(r != null) return unescape(r[2]);
// return null;
// }
// router.beforeEach((to, from, next) => {
//   // if (!to.path.includes('scope')) {
//     let code = getUrlParam("code");
//     // 已登录
//     if (code) {
//       console.log(code)
//       sessionStorage.setItem("_token", code);
//       // sessionStorage.setItem("_token", 'oaS9uw1FG7VmGLVB9N2Rm5ty9FGM');

//       next();//释放路由 如果已经登录
//     } else {
//       sessionStorage.setItem('beforeUrl', to.fullPath);
//       let local = encodeURIComponent('https://cashier-zmnh.bowu66.cn/web/index.html')
//       window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${local}&response_type=code&scope=snsapi_base&state=STATE&connect_redirect=1#wechat_redirect`
//       // next()
//     }
//   // }

// })
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)
  next()
})










new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
