import { network } from "./network";

// 场馆列表
export let List = (data) => network("/api/reservation/getReservationMuseumList", data);
export let City = (data) => network('/api/reservation/cascadeProvinceCity', data)
export let TravelDate = (data) => network('/api/reservation/getReservationMuseumArrivalRange', data)
export let OrderDetail = (data) => network('/api/reservation/getReservationDetail', data)
export let getClosed = (data) => network('/api/reservation/getClosedSettingByMonth', data, 'post')
export let museumDetail =(data) => network('/api/index/v1.2.0/getMuseumDetail',data)
export let ReservationMuseum = (data) =>network('/api/reservation/getReservationMuseum',data,'post')
//身份证判断
export let Card = (data) => network('/api/reservation/checkIdCard', data)
//历史预约订单列表接口
export let myOrder = (data) => network('/api/reservation/getReservationPeopleForapi', data, 'post')
//获取验证码
export let Number = (data) => network('/api/reservation/getVerificationCode', data)
//登录
export let Login = (data) => network('/api/reservation/loginOuter', data, 'post')
//获取到馆时间
export let getSession = (data) => network('/api/reservation/getSessionTime', data, 'post')
//立即预约
export let Appointment = (data) => network('/api/reservation/saveReservationPeople', data, 'post')
//获取电子凭证
export let Electronic = (data) => network('/api/reservation/getReservationDetail', data)
//删除预约订单
export let deletOrder = (data) => network('/api/reservation/deleteReservation', data)
//取消预约订单
export let cancelOrder = (data) => network('/api/reservation/cancelAppointment', data)
//自主核销
export let hexiao = (data) => network('/api/reservation/getSelfCheckDetail', data,'post')
//注册用户
export let register = (data) => network('/api/nmg/register', data)
//获取令牌
export let getToken = (data) => network('/api/nmg/getToken', data)
//预约列表
export let clearTravelerTemplates = (data) => network('/api/reservation/clearTravelerTemplates', data)
//预约列表
export let ReservationPeopleDto = (data) => network('/api/reservation/getReservationPeopleDto', data,'post')
//获取二维码
export let QRCode = (data) => network('/api/reservation/generateAppointmentQRCode', data)
//获取自定义配置
export let Config = (data) => network('/api/reservation/getCustomExtraConfig', data)
//获取用户实名信息
export let getAuthInfo = (data) => network('/api/nmg/getAuthInfo', data)
// export let picpreFix = 'https://zm.bowu66.com/'
export let localPic = 'http://static.bowu66.com/wenlv/'
export let picpreFix = 'http://static.bowu66.com/'