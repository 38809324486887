import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from "../page/Index";
import axios from 'axios'
import store from '../store/index'
Vue.use(VueRouter);

const routes = [
  
  {
    path: '/',
    name: 'yuyueIndex',
    component: () => import("../page/yuyueIndex")
  },
  {
    path: '/yuyueIndex',
    name: 'yuyueIndex',
    meta:{
      keepAlive:true
    },
    component: () => import("../page/yuyueIndex")
  },
  {
    path: '/zheliban',
    name: 'zheliban',
    component: () => import('../page/zheliban')
  },
  {
    path: '/addVisitor',
    name: 'addVisitor',
  
    component: () => import("../page/addVisitor")

  },
  {
    path: '/historyDetail',
    name: 'historyDetail',
    component: () => import('../page/historyDetail'),

  },
  {
    path: '/Electronic',
    name: 'Electronic',
    component: () => import('../page/Electronic')
  },
  {
    path: '/Login',
    name: 'Login',
    component: () => import('../page/Login')
  },
  {
    path: '/success',
    name: 'success',
    component: () => import('../page/success')
  },
  {
    path: '/shouquan',
    name: 'shouquan',
    component: () => import('../page/shouquan')
  },

];


const router = new VueRouter({
  routes
});

export default router
